import React from "react"
import PropTypes from "prop-types"
import SingleView from './SingleView'
import { IconClose } from "../Icons"

import "./DetailsView.scss"

const DetailsView = ({ instrument, lang, originUrl }) => {
  const close = (lang === "fr")? "Retour" : "Back"

  return (
    <article className="detailsview">
      <SingleView 
        originUrl={originUrl}
        instrument={instrument}
        lang={lang} />
      <button className="detailsview__close" onClick={() => window.history.back()}>
        {close} <IconClose />
      </button>
    </article>
  )
}

DetailsView.propTypes = {
  instrument: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired
}

export default DetailsView
