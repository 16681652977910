import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Header from "../components/Header"
import DetailsView from "../components/DetailsView"

const InstrumentPage = ({ data, location }) => {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark

  return (
    <>
      <SEO 
        title={frontmatter.title}
        description={frontmatter.description}
        image={frontmatter.image}
      />
      <Layout>
        <Header 
          lang={frontmatter.lang} />
        <DetailsView 
          originUrl={location.origin}
          lang={frontmatter.lang}
          instrument={frontmatter} />
      </Layout>
    </>
  )
}

export const instrumentsQuery = graphql`
  query instrumentsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        id
        lang
        title
        familly
        image {
          childImageSharp {
            fluid(maxWidth: 1024, traceSVG: {color: "#BFAD86"}, quality: 100) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
          publicURL
          relativePath
        }
        media {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
        media_legend
        description
        hook
        intro
        music {
          title
          sub
          link
        }
        suggestion
        suggestion_link
      }
    }
  }
`

export default InstrumentPage
